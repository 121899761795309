import React from 'react'
import { Button } from 'decentraland-ui/dist/components/Button/Button'
import { ErrorContainer, ErrorDetails, ErrorVideo } from './Error'
import errorVideo from '../../videos/big-guys.mp4'
import { reload } from './utils'
import './errors.css'

export const ErrorComms = React.memo(function () {
  return (
    <ErrorContainer id="error-network-mismatch">
      <ErrorVideo src={errorVideo} playsInline={true} autoPlay={true} muted={true} loop={true} />

      <ErrorDetails
        backgroundHeader="Oops!!!"
        header={
          <>
            {' '}
            A communication link could not be <br /> established with other peers.
          </>
        }
        description={
          <>
            This might be because you are behind a restrictive network firewall, or a temporary problem with the
            selected realm. <br />
            <br />
            If you have any ad blocking extensions try turning them off for this site, and then reload.
            <br />
            You can also try a different realm.
          </>
        }
      >
        <Button primary onClick={reload}>
          Reload
        </Button>
      </ErrorDetails>
    </ErrorContainer>
  )
})
