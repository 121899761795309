import React from 'react'
import './Mobile.css'

import mobilebg from '../images/mobilebg.svg'
import twitter from '../images/Twitter-black.svg'
import telegram from '../images/Telegram-black.svg'
import discord from '../images/Discord-black.svg'
import logo from '../images/Molecule_Black.png'

export default React.memo(() => {
  return (
    <div id="moleculeMobile">
      <img src={logo} alt="" className="logo" />
      <img src={mobilebg} alt="" className="bg" />

      <span className="title">
        Play Molecule <br /> on Desktop
      </span>

      <span className="describe">
        Molecule is now not available on mobile. Please visit in your desktop browser to access Molecule.
      </span>

      <div className="icons">
        <a href="https://twitter.com/mua_muadao" target="about:blank">
          <img src={twitter} alt="" />
        </a>

        <a href="https://t.me/MUADAO" target="about:blank">
          <img src={telegram} alt="" />
        </a>

        <a href="https://discord.gg/mua-dao" target="about:blank">
          <img src={discord} alt="" />
        </a>
      </div>
    </div>
  )
})
