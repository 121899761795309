import React, { useCallback, useMemo } from 'react'
import { ProviderType } from '@mua_molecule/schemas/dist/dapps/provider-type'
import { isCucumberProvider, isDapperProvider } from '@mua_molecule/dapps/dist/lib/eth'
import { Loader } from '@mua_molecule/ui/dist/components/Loader/Loader'
import { Button } from '@mua_molecule/ui/dist/components/Button/Button'
import { LoginModal, LoginModalOptionType } from '@mua_molecule/ui/dist/components/LoginModal/LoginModal'
import { isElectron } from '../../integration/desktop'
import { EthConnectAdvice } from './EthConnectAdvice'


import './EthWalletSelector.css'


import mail   from '../../images/mail.svg'
import mobile  from '../../images/mobile.svg'
import google from '../../images/google.svg'
import apple  from '../../images/apple.svg'

export interface WalletSelectorProps {
  open: boolean
  loading: boolean
  canceling?: boolean
  provider?: ProviderType
  availableProviders?: ProviderType[]
  onLogin?: (provider: ProviderType | null, action_type?: any , authType?:any ) => void
  onCancelLogin?: () => void
  onClose?: () => void
}

export const EthWalletSelector: React.FC<WalletSelectorProps> = React.memo(
  ({ open, loading, canceling, provider, availableProviders, onLogin, onCancelLogin, onClose }) => {
    const anchor = useMemo(() => {
      const a = document.createElement('a')
      a.target = '_blank'
      a.rel = 'noreferrer noopener'
      a.href = 'https://metamask.io/download.html'
      return a
    }, [])

    const browserWallet = useMemo<LoginModalOptionType | null>(() => {
      if (availableProviders && availableProviders.length > 0) {
        const hasWallet = availableProviders.includes(ProviderType.INJECTED)
        if (hasWallet) {
          return isCucumberProvider()
            ? LoginModalOptionType.SAMSUNG
            : isDapperProvider()
            ? LoginModalOptionType.DAPPER
            : LoginModalOptionType.METAMASK
        }
      }

      return null
    }, [availableProviders])

    const handleLoginWalletConnect = useCallback(() => onLogin && onLogin(ProviderType.WALLET_CONNECT), [onLogin])
    //const handleLoginWalletLink  = useCallback(() => onLogin && onLogin(ProviderType.WALLET_LINK), [ onLogin ])
    // const handleLoginFortmatic   = useCallback(() => onLogin && onLogin(ProviderType.FORTMATIC), [ onLogin ])
    //const handleLoginUnipass = useCallback(() => onLogin && onLogin(ProviderType.UNIPASS), [onLogin])
    
    const handleLoginUnisat = useCallback(() => onLogin && onLogin(ProviderType.UNISAT), [onLogin])
    const handleLoginOKXwallet = useCallback(() => onLogin && onLogin(ProviderType.OKXWALLET), [onLogin])
    

    const handleLoginParticle_email     = useCallback(() => onLogin && onLogin(ProviderType.PARTICLE ,null, "email"), [onLogin])
    const handleLoginParticle_phone     = useCallback(() => onLogin && onLogin(ProviderType.PARTICLE ,null, "phone"), [onLogin])
    const handleLoginParticle_google    = useCallback(() => onLogin && onLogin(ProviderType.PARTICLE ,null, "google"), [onLogin])
    const handleLoginParticle_apple     = useCallback(() => onLogin && onLogin(ProviderType.PARTICLE ,null, "apple"), [onLogin])
    

    const handleLoginInjected = useCallback(() => {
      if (browserWallet && onLogin) {
        onLogin(ProviderType.INJECTED)
      } else {
        anchor.click()
      }
    }, [onLogin, browserWallet, anchor])

    return (
      <LoginModal
        open={open}
        onClose={onClose}
        i18n={{
          title: 'Connect your wallet',
          error: '',
          subtitle: ''
        }}
      >

        {/* OKXWALLET */}
        {!isElectron() && <LoginModal.Option type={LoginModalOptionType.OKXWALLET} onClick={handleLoginOKXwallet} /> }
        
        
        {/* METAMASK */}
        {!isElectron() ? (
          <LoginModal.Option type={browserWallet || LoginModalOptionType.METAMASK} onClick={handleLoginInjected} />
        ) : (
          // Workaround to show metamask option on desktop that shows the wallet connect QR.
          <LoginModal.Option type={LoginModalOptionType.METAMASK} onClick={handleLoginWalletConnect} />
        )}

        {/* UNISAT */}
        {!isElectron() && <LoginModal.Option type={LoginModalOptionType.UNISAT} onClick={handleLoginUnisat} /> }


            

        {/* PARTICLE */}
       
        <div className="line-container">
            <div className="line"></div>
            <span className="text">Or</span>
            <div className="line"></div>
        </div>

        <div className="particle-authtypes">
            <img src={mail} width="40" alt="Mail"  onClick={handleLoginParticle_email} />  
            <img src={mobile} width="40" alt="Mobile"  onClick={handleLoginParticle_phone}/>  
            <img src={google} width="40" alt="Google"  onClick={handleLoginParticle_google}/>  
            <img src={apple} width="40" alt="Apple"  onClick={handleLoginParticle_apple}/>  
        </div>

        {/* FORTMATIC */}
        {/* {!isElectron() && <LoginModal.Option type={LoginModalOptionType.FORTMATIC} onClick={handleLoginFortmatic} />} */}

        {loading && (
          <div className="loader-background">
            <Loader active={loading} provider={provider} size="massive" />
            <EthConnectAdvice provider={provider} style={{ marginTop: '27px' }} />
            <div style={{ marginTop: '22px' }}>- or -</div>
            <Button primary onClick={onCancelLogin} loading={canceling} style={{ marginTop: '28px' }}>
              Cancel login
            </Button>
          </div>
        )}
      </LoginModal>
    )
  }
)
