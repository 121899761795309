import { fetchFlags } from '@mua_molecule/feature-flags'
import { setFeatureFlags } from '../state/actions'
import { store } from '../state/redux'
import { FF_APPLICATION_NAME } from '../state/types'
import { callOnce } from "../utils/callOnce"

export const initializeFeatureFlags = callOnce(async () => {

  const explorerFeatureFlags = "https://" + process.env.REACT_APP_FEATURE_FLAG_HOST + "/feature-flags/"
  const ff = await fetchFlags({ applicationName: FF_APPLICATION_NAME , featureFlagsUrl: explorerFeatureFlags })
  store.dispatch(setFeatureFlags(ff))
})

