import React from 'react'
import { StoreType } from '../../state/redux'
import { FeatureFlags, getFeatureVariantName, VariantNames } from '../../state/selectors'
import { isElectron } from '../../integration/desktop'
import { connect } from 'react-redux'
import './LogoContainer.css'
import Molecule_White from '../../images/Molecule_White.png'

const mapStateToProps = (state: StoreType): LogoContainerProps => {
  return {
    isSignInFlowV3: getFeatureVariantName(state, FeatureFlags.SignInFlowV3) === VariantNames.New && !isElectron()
  }
}

export interface LogoContainerProps {
  isSignInFlowV3: boolean
}

export const LogoContainer: React.FC<LogoContainerProps> = ({ isSignInFlowV3 }) => {
  return (
    <div className="LogoContainer">
      <img src={Molecule_White} width="300" alt="Molecule White logo" />
      <p>Sign In or Create an Account</p>
    </div>
  )
}

export default connect(mapStateToProps)(LogoContainer)
