import React from 'react'
import { Navbar as Base } from'decentraland-ui/dist/components/Navbar/Navbar'
import { JoinDiscord } from '../Button/JoinDiscord'
//import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu'

export type NavbarProps = {
  rightMenu?: React.ReactNode
}

export const Navbar = React.memo(function (props: NavbarProps) {
  return <Base
    isFullscreen
    leftMenu={<></>}
    rightMenu={props.rightMenu ?? <JoinDiscord />}
  />
})