import React from 'react'
import { Button } from 'decentraland-ui/dist/components/Button/Button'
import { ErrorContainer, ErrorDetails, ErrorVideo } from './Error'
import errorVideo from '../../videos/big-guys.mp4'
import { reload } from './utils'
import './errors.css'

export const ErrorAvatarLoading = React.memo(function () {
  return (
    <ErrorContainer id="error-network-mismatch">
      <ErrorVideo src={errorVideo} playsInline={true} autoPlay={true} muted={true} loop={true} />

      <ErrorDetails
        backgroundHeader="Oops!!!"
        header="There was a technical issue and we were unable to retrieve your avatar information."
        description={
          <>
            Please try again later, and if the problem persists you can contact us through the Discord channel or at{' '}
            <a href="mailto:hello@decentraland.org">hello@decentraland.org</a>
          </>
        }
      >
        <Button primary onClick={reload}>
          Reload
        </Button>
      </ErrorDetails>
    </ErrorContainer>
  )
})
