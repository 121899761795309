import React from 'react'

// import video_mualoading from '../../../images/loading.mp4'
import Molecule_black from '../../../images/Molecule_Black.png'
import LoadingImage from '../../../images/errors/error-robotdown.svg'

//<video src={video} loop width="150" height="150" muted autoPlay />
//<img src={muadao_logo} width="250" height="250" />
//import muadao_logo from '../../../images/muadao_white.png'

import './LoadingRender.css'

export const LoadingRender = React.memo(function () {
  return <div className="LoadingRender">
    
    <img className="Logo" src={Molecule_black} width="130" alt="Molecule Black logo" />  
    <img className="LoadingImage" src={LoadingImage} width="200" alt="Loading" />
    <p>We are downloading the latest version of Molecule. You'll be up and running in a few seconds!</p>
  </div>
})
