import React from 'react'
import { Button } from 'decentraland-ui/dist/components/Button/Button'
import { ErrorContainer, ErrorDetails, ErrorVideo } from './Error'
import errorVideo from '../../videos/big-guys.mp4'
import { reload } from './utils'
import './errors.css'

export const ErrorNotSupported = React.memo(function () {
  return (
    <ErrorContainer id="error-network-mismatch">
      <ErrorVideo src={errorVideo} playsInline={true} autoPlay={true} muted={true} loop={true} />

      <ErrorDetails
        backgroundHeader="Oops!!!"
        header="Your browser or device is not supported."
        description="The Explorer only works on Chrome or Firefox for Windows, Linux and macOS."
      >
        <Button primary onClick={reload}>
          Reload
        </Button>
      </ErrorDetails>
    </ErrorContainer>
  )
})
