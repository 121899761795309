import React from 'react'
import './Footer.css'
import discord from '../../../images/footer/Discord.png'
import telegram from '../../../images/footer/Telegram.png'
import twitter from '../../../images/footer/Twitter.png'

export const Footer: React.FC = () => (
  <footer className="footer-bar">
    <div className="footer-bar-content">
      <div className="left">
        <a href="https://twitter.com/mua_muadao" target="about:blank">
          <img alt="twitter" src={twitter} />
        </a>
        <a href="https://t.me/MUADAO" target="about:blank">
          <img alt="telegram" src={telegram} />
        </a>
        <a href="https://discord.gg/mua-dao" target="about:blank">
          <img alt="discord" src={discord} />
        </a>
      </div>
      <div className="right">
        <span className="footer-text">Powered by MUADAO</span>
      </div>
    </div>
  </footer>
)
