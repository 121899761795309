import React from 'react'
import { ProviderType } from '@mua_molecule/schemas/dist/dapps/provider-type'
import { Button } from '@mua_molecule/ui/dist/components/Button/Button'
import { Loader } from '@mua_molecule/ui/dist/components/Loader/Loader'
import guest from '../../images/guest.svg'
import wallets from '../../images/wallet-img.svg'
import { EthConnectAdvice } from './EthConnectAdvice'


import mail   from '../../images/mail.svg'
import mobile  from '../../images/mobile.svg'
import google from '../../images/google.svg'
import apple  from '../../images/apple.svg'

import './LoginItemContainer.css'

export type LoginItemContainerProps = {
  onClick?: () => void
  onCancelLogin?: () => void
  className?: string
  loading?: boolean
  canceling?: boolean
  active?: boolean
  provider?: ProviderType
  children?: React.ReactNode
}

export const LoginItemContainer = React.memo(
  ({ children, className, loading, canceling, active, provider, onClick, onCancelLogin }: LoginItemContainerProps) => (
    <div
      className={`LoginItemContainer ${className || ''} ${loading ? 'loading' : ''} ${active ? 'active' : ''}`}
      onClick={loading ? undefined : onClick}
    >
      <div className="LoginItemContainer__Content">{children}</div>
      {loading && active && (
        <div className="loader-background">
          <Loader active={active && loading} provider={provider} size="massive" />
          {provider && <EthConnectAdvice provider={provider} style={{ marginTop: '27px' }} />}
          {onCancelLogin && provider && <div style={{ marginTop: '22px' }}>- or -</div>}
          {onCancelLogin && provider && (
            <Button primary onClick={onCancelLogin} loading={canceling} style={{ marginTop: '28px' }}>
              Cancel
            </Button>
          )}
        </div>
      )}
    </div>
  )
)

export const LoginWalletItem = React.memo((props: LoginItemContainerProps) => (
  <LoginItemContainer {...props} className={`LoginWalletItem ${props.className || ''}`}>
    <h2>Play using your wallet</h2>
    <p>You can link your account via email, phone number, apple ID, wallet, enjoy molecule!</p>
    <div className="particle-authtypes">
            <img src={mail} width="20" alt="Mail"   />  
            <img src={mobile} width="20" alt="Mobile"  />  
            <img src={google} width="20" alt="Google"  />  
            <img src={apple} width="20" alt="Apple"  />  
        </div>

    <img alt="wallets" src={wallets} width="280" height="280" className="wallet-img" />

    <Button
      primary
      size="huge"
      loading={(props.loading || props.canceling) && !props.active}
      disabled={props.loading || props.canceling}
    >
      Continue with wallet
    </Button>
  </LoginItemContainer>
))

export const LoginGuestItem = React.memo((props: LoginItemContainerProps) => (
  <LoginItemContainer {...props} className={`LoginGuestItem ${props.className || ''}`}>
    <h2>Play as guest</h2>
    <p>Your information will be locally stored and your experience limited.</p>

    <img alt="guest" src={guest} width="280" height="280" />

    <Button
      primary
      size="huge"
      loading={(props.loading || props.canceling) && !props.active}
      disabled={props.loading || props.canceling}
    >
      Continue as guest
    </Button>
    

  </LoginItemContainer>
))
