import React from 'react'
import { Button } from 'decentraland-ui/dist/components/Button/Button'
import { ErrorContainer, ErrorDetails, ErrorVideo } from './Error'
import errorVideo from '../../videos/big-guys.mp4'
import './errors.css'

export interface ErrorNetworkMismatchProps {
  details: string | null
  closeError(): void
}

export const ErrorMetamaskLocked = React.memo(function (props: ErrorNetworkMismatchProps) {
  return (
    <ErrorContainer id="error-network-mismatch">
      <ErrorVideo src={errorVideo} playsInline={true} autoPlay={true} muted={true} loop={true} />

      <ErrorDetails backgroundHeader="Oops!!!" header="Before we continue." description={props.details}>
        <Button primary onClick={props.closeError}>
          Retry
        </Button>
      </ErrorDetails>
    </ErrorContainer>
  )
})
